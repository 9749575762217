import React from "react"

const FooterMenusWidgets = ({}) => {
  return (
    <div className="footer-nav-widgets-wrapper header-footer-group">
      <div className="footer-inner section-inner">
      </div>
    </div>
  )
}

export default FooterMenusWidgets
