import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import Menu from "./Menu"
import ToggleIcon from "../assets/svg/toggle.inline.svg"

const Header = ({ pageContext, toggleBackdrop, ...props }) => {
  const { wp } = useStaticQuery(graphql`
    {
      wp {
        generalSettings {
          title
          description
        }
      }
    }
  `)
  return (
    <header id="site-header" className="header-footer-group" role="banner">
      <div className="header-inner section-inner">
        <div className="header-titles-wrapper">
          <div className="header-titles">
            <h1 className="site-title">
              <Link to="/">
                <img src={'/logo.png'} alt="Logo" />
              </Link>
            </h1>
            <div
              className="site-description"
              dangerouslySetInnerHTML={{
                __html: wp.generalSettings.description,
              }}
            />
          </div>
        </div>

        <div className="header-navigation-wrapper">
          <Menu />
        </div>
      </div>
    </header>
  )
}

export default Header
